import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import Layout from '../components/layout';
import { Content, Header, Page, TextPageHeader } from '../components/page';
import Seo from '../components/seo';
import styles from '../sass/components/text.module.scss';
import getString from '../utils/get-string';

export default ({ data, ...rest }) => (
  <Layout>
    <Seo title={data.datoCmsPage.title} />

    <Page type="textpage">
      <Header closeUrl="/lessons#dont-drink-and-drive">
        {(rest.location.state && rest.location.state.loginText && (
          <span className="_color-danger">{rest.location.state.loginText}</span>
        )) ||
          getString(data.strings, "11220959")}
      </Header>
      <Content center={true}>
        <TextPageHeader>
          {data.datoCmsPage.image && (
            <Img
              style={{ maxWidth: '100%' }}
              fixed={data.datoCmsPage.image.fixed}
              imgStyle={{ objectPosition: '', objectFit: '' }}
              Tag="figure"
              alt={data.datoCmsPage.title}
              critical={true}
            />
          )}
          <h1>{data.datoCmsPage.title}</h1>
        </TextPageHeader>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: data.datoCmsPage.content,
          }}
        />
      </Content>
    </Page>
  </Layout>
)

export const query = graphql`
  query($id: String!) {
    datoCmsPage(originalId: { eq: $id }) {
      id
      title
      content
      image {
        id
        fixed(width: 200) {
          ...GatsbyDatoCmsFixed
        }
      }
    }

    strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11220959"]}}) {
      edges {
        node {
          string
          original
          originalId
        }
      }
    }
  }
`
